import { TableCell, TableRow, Tooltip } from "@material-ui/core";
import React from "react";
import { Some } from "../../../utils/Some";

interface Props {
  readonly eventAttendeeId: number;
  readonly firstName: string;
  readonly email: string;
  readonly lastName: string;
  readonly status?: string;
}

export const ShowEventAttendeeDetails: React.FC<Props> = ({ eventAttendeeId, firstName, lastName, email, status }) => {
  return (
    <TableRow key={eventAttendeeId}>
      <TableCell component="th" scope="row" className="record_data">
        {firstName}&nbsp;&nbsp;{lastName}
      </TableCell>
      <TableCell align="right" className="record_data">
        {email}
      </TableCell>
      {Some(status) && (
        <TableCell align="right" className="record_data">
          <Tooltip title={status}>
            <span style={{ color: "red", cursor: "pointer", textDecoration: "underline" }}>failed</span>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};
