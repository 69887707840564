import "./EventCheckins.css";
import { AppBar, Grid, Menu, MenuItem, withStyles } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { WithErrorHandler, WithErrorHandlerProps } from "../../WithErrorHandler";
import { deleteCheckinLog, exportCheckIns, getAllActivities, searchEventAttendee } from "../../../constant/api";
import AddNotes from "./Modals/AddNotes";
import CheckIn from "../EventCheckins/Modals/CheckInModal";
import CheckInBulkModal from "../CheckinBulk/Modals/CheckInBulkModal";
import { CheckInLogTable } from "./CheckInLogTable";
import { CheckinHeader } from "./CheckinHeader";
import { CheckinMatchModal } from "./Modals/CheckinMatchModal";
import CommonHeader from "../../UI-Components/CommonHeader";
import Cookies from "js-cookie";
import CopyQualification from "./Modals/CopyQualification";
import { DeleteCheckinModal } from "./Modals/DeleteCheckinModal";
import EditCheckInCheckOutTimeModal from "./Modals/EditCheckInCheckOutTimeModal";
import { EditMemberNameModal } from "./Modals/EditMemberNameModal";
import React from "react";
import { Some } from "../../../utils/Some";
import Welcome from "../Welcome/Welcome";
import axios from "axios";
import moment from "moment-timezone";
import noCheckins from "../../../assets/Images/Icons/noCheckins.svg";

interface LocationState {
  data: {
    eventId: string;
    event: string;
    uncheckedData: boolean;
  };
  // Add more properties as needed
}

interface PropsForAllCheckins extends WithErrorHandlerProps, RouteComponentProps<{}, {}, LocationState> {
  readonly notify: (message: string, status: string) => void;
  readonly classes: {
    readonly root: string;
    readonly table: string;
    readonly tableWrapper: string;
    readonly input: string;
  };
  readonly allowOfflineMode: boolean;
}

interface State {
  anchorElForMenu: any;
  selected: any;
  order: string;
  orderBy: string;
  data: EventAttendee[];
  editCheckinVisible: boolean;
  addNotes: boolean;
  eventAttendeeIds: number[];
  selectedAttendees: EventAttendee[];
  selectedAttendee: EventAttendee[];
  eventFilterInfo: FilterInfo;
  noCheckinsAnywhere: boolean;
  currentDate: string;
  // Added null to support existing bulk updates
  checkInTz: string | null;
  checkOutTz: string | null;
  events: EventList[];
  anchorEl: any;
  note: string | null | undefined;
  totalPages: number;
  showBulkCheckIns: boolean;
  showCheckIns: boolean;
  copyQualification: boolean;
  attendeeMerits: string;
  uncheckedData: boolean;
  loading: boolean;
  showDeleteCheckInModal: boolean;
  showMatchModal: boolean;
  checkInTime: string;
  checkOutTime: string;
  showEditCheckInCheckOutTimeModal: boolean;
}
interface Option {
  readonly eventId: any;
  readonly name: string;
}

interface FilterInfo {
  eventId: string;
  eventName: string;
  firstName: string;
  lastName: string;
  email: string;
  page: number;
}

const styles = (theme: { spacing: { unit: number } }) => ({
  input: {
    paddingBottom: "10.5px",
    paddingTop: "10.5px",
  },
  root: {
    marginTop: theme.spacing.unit * 3,
    width: "100%",
  },
  tableWrapper: {
    height: "calc(100vh - 250px)",
    marginTop: "8px",
    overflow: "auto",
    width: "100%",
  },
});

class AllCheckins extends React.PureComponent<PropsForAllCheckins, State> {
  isLoading: boolean;
  constructor(props: PropsForAllCheckins) {
    super(props);
    this.isLoading = true;

    const { location } = this.props;
    const filterInfo: FilterInfo = {
      email: "",
      eventId: location.state === undefined ? "" : location.state.data.eventId,
      eventName: location.state === undefined ? "" : location.state.data.event,
      firstName: "",
      lastName: "",
      page: 1,
    };

    this.state = {
      addNotes: false,
      anchorEl: null,
      anchorElForMenu: null,
      attendeeMerits: "",
      checkInTime: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      checkInTz: null,
      checkOutTime: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      checkOutTz: null,
      copyQualification: false,
      currentDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      data: [],
      editCheckinVisible: false,
      eventAttendeeIds: [],
      eventFilterInfo: filterInfo,
      events: [],
      loading: false,
      noCheckinsAnywhere: false,
      note: "",
      order: "asc",
      orderBy: "",
      selected: [],
      selectedAttendee: [],
      selectedAttendees: [],
      showBulkCheckIns: false,
      showCheckIns: false,
      showDeleteCheckInModal: false,
      showEditCheckInCheckOutTimeModal: false,
      showMatchModal: false,
      totalPages: 0,
      uncheckedData: location.state === undefined ? false : location.state.data.uncheckedData,
    };
    if (location.state === undefined) {
      this.getAllCheckins(1);
    } else {
      this.searchAllEventAttendees(filterInfo);
    }
  }

  registerScroll = () => {
    const body = document.getElementById("table");
    const { totalPages, eventFilterInfo } = this.state;
    if (body !== null) {
      body.onscroll = () => {
        if (body.scrollTop > body.scrollHeight - body.offsetTop - 608) {
          if (!this.isLoading && eventFilterInfo.page < totalPages) {
            eventFilterInfo.page = eventFilterInfo.page + 1;
            this.searchAllEventAttendees(eventFilterInfo);
          }
        }
      };
    }
  };

  deleteCheckinLog = async () => {
    const { selectedAttendees } = this.state;
    const eventAttendeeId = selectedAttendees.map(({ eventAttendeeId }: EventAttendee) => eventAttendeeId);
    const params = {
      eventAttendeeId,
    };

    try {
      const response: DeleteCheckinLogResponse = await deleteCheckinLog(params);
      const { notify } = this.props;
      this.handleCloseDeleteCheckin();
      if (response.data.status === "success") {
        notify("Checkin deleted successfully.", "success");
      } else {
        notify("Unable to delete this checkin.", "error");
      }
    } catch (error: unknown) {
      const { errorHandler } = this.props;
      errorHandler(error);
    }
  };

  getAllCheckins = (page: number) => {
    this.searchAllEventAttendees({ email: "", eventId: "", eventName: "", firstName: "", lastName: "", page: page });
  };

  searchAllEventAttendees = async (params: FilterInfo) => {
    this.isLoading = true;

    const { events, noCheckinsAnywhere } = this.state;
    if (events.length === 0) {
      try {
        const responseEvents = await getAllActivities();
        if (responseEvents.data.item.length !== 0) {
          this.setState({
            events: responseEvents.data.item,
          });
        }
      } catch (error: unknown) {
        const { errorHandler } = this.props;
        errorHandler(error);
      }
    }

    const { data } = this.state;
    const queryParams = {
      email: params.email,
      eventId: params.eventId,
      firstName: params.firstName,
      lastName: params.lastName,
      page: params.page,
    };
    try {
      const response: any = await searchEventAttendee(queryParams);
      let matchingCheckins: EventAttendee[];
      if (params.page === 1) {
        matchingCheckins = response.data.item;
      } else {
        matchingCheckins = data.concat(response.data.item);
      }
      if (response.data.item.length !== 0) {
        const { uncheckedData } = this.state;
        if (uncheckedData) {
          matchingCheckins = matchingCheckins.filter((checkinData: EventAttendee) => {
            return checkinData.checkOut === null;
          });
        }
      }
      this.isLoading = false;
      this.setState({
        anchorEl: null, // is this needed?
        data: matchingCheckins,
        eventFilterInfo: params,
        loading: false,
        noCheckinsAnywhere: noCheckinsAnywhere && matchingCheckins.length === 0,
        totalPages: response.data.metadata.totalPages,
      });
      this.registerScroll();
    } catch (error: unknown) {
      const { errorHandler } = this.props;
      errorHandler(error);
    }
  };

  handleRequestSort = (event: any, property: string) => {
    const orderBy = property;
    let order = "desc";
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;
    if (stateOrderBy === property && stateOrder === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event: boolean) => {
    if (event) {
      this.setState((state) => ({
        note: "",
        selected: state.data.map((n) => n.eventAttendeeId),
        selectedAttendees: state.data,
      }));

      return;
    }
    this.setState({ selected: [], selectedAttendees: [] });
  };

  getFilteredAndClearEventAttendeeIds = () => {
    const { eventFilterInfo } = this.state;
    this.searchAllEventAttendees(eventFilterInfo);
    this.clearEventAttendeeIds();
  };

  handleCloseEditCheckInCheckOutTimeModal = () => {
    this.setState({ showEditCheckInCheckOutTimeModal: false });
    this.getFilteredAndClearEventAttendeeIds();
  };

  handleCloseEditModal = () => {
    this.setState({ editCheckinVisible: false });
    this.getFilteredAndClearEventAttendeeIds();
  };

  handleCloseAddNotes = () => {
    this.setState({ addNotes: false });
    this.getFilteredAndClearEventAttendeeIds();
  };

  handleCloseDeleteCheckin = () => {
    this.setState({
      showDeleteCheckInModal: false,
    });
    this.getFilteredAndClearEventAttendeeIds();
  };

  clearEventAttendeeIds = () => {
    this.setState({
      eventAttendeeIds: [],
      selected: [],
      selectedAttendees: [],
    });
  };

  handleCloseCopy = () => {
    this.setState({
      copyQualification: false,
    });
  };

  handleClick = (cell: EventAttendee) => {
    const { selected } = this.state;
    let { selectedAttendees: eventAttendees } = this.state;
    const selectedIndex = selected.indexOf(cell.eventAttendeeId);
    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, cell.eventAttendeeId);
      eventAttendees.push(cell);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      eventAttendees = eventAttendees.filter((value) => {
        return value.eventAttendeeId !== cell.eventAttendeeId;
      });
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      eventAttendees = eventAttendees.filter((value) => {
        return value.eventAttendeeId !== cell.eventAttendeeId;
      });
    } else if (selectedIndex > 0) {
      eventAttendees = eventAttendees.filter((value) => {
        return value.eventAttendeeId !== cell.eventAttendeeId;
      });
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    this.setState({
      note: "",
      selected: newSelected,
      selectedAttendees: eventAttendees,
    });
  };

  handleOptionClick = (e: any, option: any, eventAttendee: EventAttendee) => {
    e.preventDefault();
    const optionState = option.replace(/ /gu, "");
    const eventAttendees = [];
    const attendee = [];
    attendee.push(eventAttendee);
    eventAttendees.push(eventAttendee.eventAttendeeId);
    this.setState({
      attendeeMerits: eventAttendee.attendeeMerit,
      checkOutTz: eventAttendee.checkOutTz || eventAttendee.event.timezone,
      currentDate:
        option === "checkout" && eventAttendee.checkOut
          ? moment(eventAttendee.checkOut).tz(eventAttendee.checkOutTz).format("YYYY-MM-DDTHH:mm")
          : moment(new Date()).tz(eventAttendee.event.timezone).format("YYYY-MM-DDTHH:mm"),
      eventAttendeeIds: eventAttendees,
      note: eventAttendee.notes,
      [optionState]: true,
      selectedAttendees: attendee,
    } as Pick<State, keyof State>);
  };

  // eslint-disable-next-line react/destructuring-assignment
  isSelected = (eventAttendeeId: number) => this.state.selected.indexOf(eventAttendeeId) !== -1;

  editCheckInCheckOutTime(eventAttendee: EventAttendee) {
    const { selectedAttendees } = this.state;

    if (selectedAttendees.length > 1) {
      this.onEditCheckInCheckOutTimeBulk();

      return;
    }
    const {
      checkOut,
      checkOutTz: checkOutTimeZone,
      event: { timezone },
      checkInTz,
      checkIn,
      eventAttendeeId,
    } = eventAttendee;
    const checkOutTz = checkOutTimeZone || timezone;
    this.setState({
      checkInTime: moment(checkIn).tz(checkInTz).format("YYYY-MM-DDTHH:mm"),
      checkInTz: checkInTz,
      checkOutTime: moment(checkOut || new Date())
        .tz(checkOutTz)
        .format("YYYY-MM-DDTHH:mm"),
      checkOutTz,
      eventAttendeeIds: [eventAttendeeId],
      selectedAttendees: [eventAttendee],
      showEditCheckInCheckOutTimeModal: true,
    });
  }

  editMemberName(eventAttendee: EventAttendee) {
    this.setState({
      editCheckinVisible: true,
      eventAttendeeIds: [eventAttendee.eventAttendeeId],
      selectedAttendee: [eventAttendee],
    });
  }

  addNotesBulk = () => {
    const { selected: selectedData } = this.state;
    if (selectedData.length !== 0) {
      this.setState({
        addNotes: true,
        eventAttendeeIds: selectedData,
      });
    }
  };
  onDeleteBulk = () => {
    this.setState({
      showDeleteCheckInModal: true,
    });
  };

  onMatchCheckinLog = () => {
    const { selected: selectedData } = this.state;
    if (selectedData.length !== 0) {
      this.setState({
        eventAttendeeIds: selectedData,
        showMatchModal: true,
      });
    }
  };

  onEditCheckInCheckOutTimeBulk = () => {
    const { selected: selectedData, selectedAttendees } = this.state;

    if (selectedData.length > 1) {
      const {
        event: { timezone },
      } = selectedAttendees[0];

      this.setState({
        checkInTime: moment(new Date()).tz(timezone).format("YYYY-MM-DDTHH:mm"),
        checkInTz: timezone,
        checkOutTime: moment(new Date()).tz(timezone).format("YYYY-MM-DDTHH:mm"),
        checkOutTz: timezone,
        eventAttendeeIds: selectedData,
        showEditCheckInCheckOutTimeModal: true,
      });
    } else if (selectedData.length === 1) {
      const {
        event: { timezone },
        checkOut,
        checkOutTz,
        checkInTz,
        checkIn,
      } = selectedAttendees[0];
      const isCheckoutDoneAlready = Some(checkOut) && Some(checkOutTz);

      this.setState({
        checkInTime: moment(checkIn).tz(checkInTz).format("YYYY-MM-DDTHH:mm"),
        checkInTz: checkInTz,
        checkOutTime: isCheckoutDoneAlready
          ? moment(checkOut).tz(checkOutTz).format("YYYY-MM-DDTHH:mm")
          : moment(new Date()).tz(timezone).format("YYYY-MM-DDTHH:mm"),
        checkOutTz: checkInTz || timezone,
        eventAttendeeIds: selectedData,
        showEditCheckInCheckOutTimeModal: true,
      });
    }
  };

  openMenu = (event: { currentTarget: any }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = async (e: any, option: Option) => {
    e.preventDefault();
    if (option.eventId === undefined) {
      this.setState({ anchorEl: null });
    } else {
      const { eventFilterInfo } = this.state;
      this.searchAllEventAttendees({
        email: eventFilterInfo.email,
        eventId: option.eventId,
        eventName: option.name,
        firstName: eventFilterInfo.firstName,
        lastName: eventFilterInfo.lastName,
        page: 1,
      });
    }
  };

  download = () => {
    const { selectedAttendees } = this.state;
    exportCheckIns({
      eventId: selectedAttendees.map((attendee) => attendee.eventId.toString()),
      fromDate: "",
      toDate: "",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data as unknown as BlobPart]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Check-ins.xlsx"); // or any other extension
        const body: any = document.body;
        body.appendChild(link);
        link.click();
      })
      .catch((error: unknown) => {
        const { errorHandler } = this.props;
        errorHandler(error);
      });
  };

  downloadWaiver = (e: any, eventAttendee: any) => {
    e.preventDefault();
    const cookie: any = Cookies.get("auth");

    axios({
      headers: { Authorization: `Bearer ${cookie}` },
      method: "GET",
      responseType: "blob", // important
      url: `/api/waiver/download-attendee-waiver?eventAttendeeId=${eventAttendee.eventAttendeeId}`,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "waiver.pdf"); // or any other extension
      const body: any = document.body;
      body.appendChild(link);
      link.click();
    });
  };

  checkIn = () => {
    this.setState({
      showCheckIns: true,
    });
  };

  bulkCheckins = () => {
    this.setState({
      showBulkCheckIns: true,
    });
  };

  handleCloseCheckIn = () => {
    this.setState({ showCheckIns: false });
  };

  handleCloseBulkCheckIns = () => {
    this.setState({ showBulkCheckIns: false });
  };

  welcomeScreen = () => {
    return (
      <Grid container>
        <Welcome
          imagePath={noCheckins}
          header="No Member Check-ins"
          text="You can manually check-in and check-out members or see list of members who have done so via the self-service app."
          buttonText="Check-in participant"
          checkIn={this.checkIn}
          testId="noMemberCheckins"
        />
      </Grid>
    );
  };

  notify = (message: string, status: string) => {
    const { notify } = this.props;
    notify(message, status);
  };

  handleClose = () => {
    this.setState({ anchorElForMenu: null });
  };

  handleMenuItemClick = (index: number) => {
    if (index === 0) {
      this.setState({ addNotes: true });
    } else if (index === 1) {
      this.setState({
        showMatchModal: true,
      });
    } else if (index === 2) {
      this.setState({
        showDeleteCheckInModal: true,
      });
    }
    this.handleClose();
  };

  handleClickAction = (event: any, n: any) => {
    const { anchorElForMenu } = this.state;
    if (anchorElForMenu) {
      this.setState({
        anchorElForMenu: null,
        attendeeMerits: "",
        currentDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
        eventAttendeeIds: [],
        note: "",
        selectedAttendees: [],
      });
    } else {
      const eventAttendees = [];
      const attendee = [];
      attendee.push(n);
      eventAttendees.push(n.eventAttendeeId);
      this.setState({
        anchorElForMenu: event.currentTarget,
        attendeeMerits: n.attendeeMerit,
        currentDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
        eventAttendeeIds: eventAttendees,
        note: n.notes,
        selectedAttendees: attendee,
      });
    }
  };

  buildCheckinTable: () => JSX.Element = () => {
    const { classes } = this.props;
    const { data, loading, eventFilterInfo } = this.state;

    return (
      <div className={classes.tableWrapper} id="table">
        <CheckInLogTable
          checkins={data}
          handleSelectRow={(eventAttendee: EventAttendee) => this.handleClick(eventAttendee)}
          handleSelectAllClick={(event) => this.handleSelectAllClick(event)}
          editCheckInCheckOutTime={(eventAttendee) => this.editCheckInCheckOutTime(eventAttendee)}
          editMemberName={(eventAttendee) => this.editMemberName(eventAttendee)}
          handleOptionClick={(event, option, eventAttendee) => this.handleOptionClick(event, option, eventAttendee)}
          downloadWaiver={(e, eventAttendee) => this.downloadWaiver(e, eventAttendee)}
          handleClickAction={(event, eventAttendee) => this.handleClickAction(event, eventAttendee)}
          handleMenuItemClick={(index) => this.handleMenuItemClick(index)}
          loading={loading}
          addNotesBulk={() => this.addNotesBulk()}
          onEditCheckInCheckOutTimeBulk={() => this.onEditCheckInCheckOutTimeBulk()}
          onDeleteBulk={() => this.onDeleteBulk()}
          onMatchCheckinLog={() => this.onMatchCheckinLog()}
          pageNumber={eventFilterInfo.page}
          notify={this.notify}
        />
      </div>
    );
  };

  render() {
    const {
      events,
      anchorEl,
      selectedAttendee,
      eventFilterInfo,
      selectedAttendees,
      addNotes,
      eventAttendeeIds,
      note,
      showBulkCheckIns,
      showCheckIns,
      copyQualification,
      attendeeMerits,
      currentDate,
      checkInTz,
      checkOutTz,
      editCheckinVisible,
      showDeleteCheckInModal,
      showMatchModal,
      noCheckinsAnywhere,
      checkInTime,
      checkOutTime,
      showEditCheckInCheckOutTimeModal,
    } = this.state;
    const { allowOfflineMode } = this.props;
    const open = Boolean(anchorEl);

    return (
      <div>
        <AppBar position="static" className="appbar app_bar">
          <CommonHeader
            name="Check-in log"
            buttonText="Check-in participant"
            handleClick={this.checkIn}
            testId="checkinLog"
            secondaryButton={{ buttonText: "Check-in multiple participants", handleClick: this.bulkCheckins }}
            text="The Check-in log shows participant engagement for all your activities. Track participant check-in activity and total participation time."
          />
        </AppBar>
        {noCheckinsAnywhere ? (
          this.welcomeScreen()
        ) : (
          <div className="common_padding_left" style={{ paddingTop: "16px" }}>
            <CheckinHeader
              allowOfflineMode={allowOfflineMode}
              activityFilter={this.openMenu}
              exportCheckIns={() => this.download()}
              open={open}
              resetClick={() => {
                this.scrollResultsToTop();
                this.searchAllEventAttendees({
                  email: "",
                  eventId: "",
                  eventName: "",
                  firstName: "",
                  lastName: "",
                  page: 1,
                });
              }}
              selectedEventName={eventFilterInfo.eventName}
              queryForCheckins={async () => {
                this.scrollResultsToTop();
                await this.searchAllEventAttendees({
                  email: eventFilterInfo.email,
                  eventId: eventFilterInfo.eventId,
                  eventName: eventFilterInfo.eventName,
                  firstName: eventFilterInfo.firstName,
                  lastName: eventFilterInfo.lastName,
                  page: 1,
                });
              }}
              updateEmail={(email: string) =>
                this.setState({
                  eventFilterInfo: {
                    email: email,
                    eventId: eventFilterInfo.eventId,
                    eventName: eventFilterInfo.eventName,
                    firstName: eventFilterInfo.firstName,
                    lastName: eventFilterInfo.lastName,
                    page: eventFilterInfo.page,
                  },
                })
              }
              updateFirstName={(firstName: string) =>
                this.setState({
                  eventFilterInfo: {
                    email: eventFilterInfo.email,
                    eventId: eventFilterInfo.eventId,
                    eventName: eventFilterInfo.eventName,
                    firstName: firstName,
                    lastName: eventFilterInfo.lastName,
                    page: eventFilterInfo.page,
                  },
                })
              }
              updateLastName={(lastName: string) =>
                this.setState({
                  eventFilterInfo: {
                    email: eventFilterInfo.email,
                    eventId: eventFilterInfo.eventId,
                    eventName: eventFilterInfo.eventName,
                    firstName: eventFilterInfo.firstName,
                    lastName: lastName,
                    page: eventFilterInfo.page,
                  },
                })
              }
            />
            <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={() => this.setState({ anchorEl: null })}>
              {events.map((event) => (
                <MenuItem
                  key={event.eventId}
                  onClick={(e) => this.closeMenu(e, event)}
                  data-testid={`${event.name}-Activity-AllCheckIns`}
                >
                  {event.name}
                </MenuItem>
              ))}
            </Menu>
            {this.buildCheckinTable()}
          </div>
        )}
        {addNotes && (
          <AddNotes
            handleClose={this.handleCloseAddNotes}
            show={addNotes}
            eventAttendee={eventAttendeeIds}
            clearEventAttendeeIds={this.clearEventAttendeeIds}
            selectedAttendees={selectedAttendees}
            note={note}
            notify={this.notify}
          />
        )}
        {showDeleteCheckInModal && (
          <DeleteCheckinModal
            selectedAttendeesCount={selectedAttendees.length}
            handleClose={this.handleCloseDeleteCheckin}
            show={showDeleteCheckInModal}
            deleteCheckin={() => this.deleteCheckinLog()}
          />
        )}
        {showMatchModal && (
          <CheckinMatchModal
            eventAttendeeId={
              eventAttendeeIds.length === 1 ? String(eventAttendeeIds[0]) : String(selectedAttendees[0].eventAttendeeId)
            }
            isVisible={showMatchModal}
            notify={this.notify}
            onClose={() => {
              this.setState({ showMatchModal: false });
              this.getFilteredAndClearEventAttendeeIds();
            }}
          />
        )}
        {showCheckIns && (
          <CheckIn
            notify={this.notify}
            getAllCheckins={async () => {
              this.scrollResultsToTop();
              await this.searchAllEventAttendees({
                email: eventFilterInfo.email,
                eventId: eventFilterInfo.eventId,
                eventName: eventFilterInfo.eventName,
                firstName: eventFilterInfo.firstName,
                lastName: eventFilterInfo.lastName,
                page: 1,
              });
            }}
            handleClose={this.handleCloseCheckIn}
            show={showCheckIns}
          />
        )}
        {showBulkCheckIns && (
          <CheckInBulkModal
            currentDate={currentDate}
            notify={this.notify}
            getAllCheckins={async () => {
              this.scrollResultsToTop();
              const { email, eventId, eventName, firstName, lastName } = eventFilterInfo;
              await this.searchAllEventAttendees({
                email,
                eventId,
                eventName,
                firstName,
                lastName,
                page: 1,
              });
            }}
            handleClose={this.handleCloseBulkCheckIns}
            show={showBulkCheckIns}
          />
        )}
        {copyQualification && (
          <CopyQualification
            handleClose={this.handleCloseCopy}
            show={copyQualification}
            eventAttendee={eventAttendeeIds}
            clearEventAttendeeIds={this.clearEventAttendeeIds}
            selectedAttendees={selectedAttendees}
            notify={this.notify}
            attendeeMerits={attendeeMerits}
          />
        )}
        {showEditCheckInCheckOutTimeModal && (
          <EditCheckInCheckOutTimeModal
            clearEventAttendeeIds={this.clearEventAttendeeIds}
            handleClose={this.handleCloseEditCheckInCheckOutTimeModal}
            show={showEditCheckInCheckOutTimeModal}
            eventAttendee={eventAttendeeIds}
            selectedAttendees={selectedAttendees}
            checkInTz={checkInTz}
            notify={this.notify}
            checkOutTz={checkOutTz}
            checkInTime={checkInTime}
            checkOutTime={checkOutTime}
          />
        )}
        {editCheckinVisible && (
          <EditMemberNameModal
            visible={editCheckinVisible}
            handleCloseEditModal={this.handleCloseEditModal}
            firstName={selectedAttendee[0].firstName}
            lastName={selectedAttendee[0].lastName}
            eventAttendeeId={eventAttendeeIds[0]}
            notify={this.notify}
          />
        )}
      </div>
    );
  }

  private scrollResultsToTop() {
    // This scrolls the table to the top after a reset
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const row = document.getElementsByClassName("ant-table-tbody")[0].rows[0];
    if (Some(row)) {
      row.scrollIntoView(false);
    }
  }
}

export default withStyles(styles)(withRouter(WithErrorHandler(AllCheckins)));
